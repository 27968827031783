<template>
    <div>
        <Header></Header>
        <div id="userCenter" :style="{ minHeight: minHeight - 330 + 'px' }">
            <div class="userCenter_box">
                <div class="userCenter_content">
                    <div class="userCenter_left">
                        <div>个人中心</div>
                        <ul>
                            <!-- 左侧导航菜单 Start-->
                            <li
                                v-for="(v, k) in leftMsg"
                                :key="k"
                                :data-name="v.name"
                                :data-index="k"
                                :class="[`left_menu_${k}`,`${changeMenuStatus == v.name ? 'active' : ''}`]"
                                @click="navMenu">
                                <i></i><span>{{ v.text }}</span>
                                <span class="msgRed_hot" v-if="v.name == 'help-feedback' && msgCount">{{msgCount}}</span>
                            </li>
                            <!-- End -->
                        </ul>
                    </div>
                    <div class="userCenter_right">
                        <!-- 循环内容渲染 -Start-->
                        <div class="userCenter_max" >
                            <div
                                v-for="(v, k) in leftMsg"
                                :key="k"
                                v-show="changeMenuStatus == v.name">
                                <component
                                    :ref="'childNode_'+ v.name"
                                    :userMsgStatus="userMsgStatus[k]"
                                    @feedbackHandler="feedbackHandler"
                                    :is="v.name"
                                    :msgBool="true"
                                />
                            </div>
                        </div>
                        <!-- End -->
                        <!-- 小屏 -Start-->
                        <div class="userCenter_min" >
                            <div v-for="(v, k) in leftMsg" :key="k">
                                <div v-if="k == 4" id="maoLink"></div>
                                <a id="maoTarget" href="#maoLink"></a>
                                <div class="min_nav" ref="minNav" v-if="k != 0">
                                    <div
                                        @click="navMenu"
                                        :data-name="v.name"
                                        :data-bool="true"
                                        :data-index="k">
                                        <i :class="['i_nav_list',`i-nav-${k}`]"></i>
                                        <span>{{ v.text }}</span>
                                        <i ref="navShowChange" class="i_nav_right"></i>
                                    </div>
                                </div>
                                <div v-show="changeMinStatus[k] || false">
                                    <component
                                        :ref="'childNode_'+ v.name"
                                        :userMsgStatus="minUserMsgStatus[k]"
                                        @feedbackHandler="feedbackHandler"
                                        :is="v.name"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- End -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>

    </div>
</template>
<script>

import Helper from "@/utils/routeStatus";

export default {
    name: "UserCenter",
    data() {
        return {
            leftMsg: [
                { text: "个人资料", name: "my-msg" },
                { text: "游戏记录", name: "playing-record" },
                { text: "我的收藏", name: "my-collect" },
                { text: "存档记录", name: "archive-record" },
                { text: "意见反馈", name: "help-feedback" },
                { text: "充值记录", name: "consume-record" },
            ],
            changeMenuStatus: "my-msg", //右侧内容显示状态的控制
            userMsgStatus: { 0: false }, //大屏请求开关
            minUserMsgStatus: { 0: true }, //小屏请求开关
            changeMinStatus: { 0: true, 1: false, 2: false, 3: false, 4: false, 5: false}, //小屏请求开关
            minHeight: 0, //内容高度
            feedback_count:0
        };
    },
    mounted() {
        // 监听屏高变化，撑开高度
        this.minHeight = document.documentElement.clientHeight;
        window.onresize = () => {
            this.minHeight = document.documentElement.clientHeight;
        };

        this.navInfo(this,this.$route.params.status,this.$route.params.size,true);

        let _this = this;
        Helper.$on('routeStatus', function (status,size) {
            _this.navInfo(_this,status,size,false);
        });
    },
    computed:{
        msgCount(){
            return this.$store.state.userCenter.feedbackPage;
        }
    },
    methods: {
        navMenu(e) {
            // 大屏点击侧边栏导航事件
            let { name, index ,bool} = e.currentTarget.dataset;

            if ("consume-record" == name ) {
                this.$store.commit("MESSAGE_TEXT", "功能暂未开放！");
            } else {

                if(name == 'my-msg'|| name == 'help-feedback'){
                    let node = this.$refs['childNode_'+name];
                        node[0].parentEvent();
                        node[1].parentEvent();
                }

                if(!bool){
                    // 开启对应component
                    this.changeMenuStatus = name;
                    this.userMsgStatus[index] = true;
                }else{
                    // 开启对应min component
                    let iChange = this.$refs.navShowChange[index - 1];
                    let navBox = this.$refs.minNav[index - 1];

                    this.changeMinStatus[index] = !this.changeMinStatus[index];
                    this.minUserMsgStatus[index] = true;

                    //图标与箭头的切换
                    iChange.className = iChange.className.includes("i_nav_right")? "": "i_nav_right";
                    navBox.className = navBox.className.includes("active")? "min_nav": "min_nav active";

                }
            }
        },
        navInfo(_this,status,bool,type){
            if(status){
                // 意见反馈
                if(document.documentElement.clientWidth > 700){
                    //大屏
                    _this.changeMenuStatus = 'help-feedback';
                }else{
                    // 小屏;
                    let nav = document.querySelector('.i-nav-4'),
                        mao = document.querySelector('#maoTarget');
                        nav.className = 'i_nav_list active i-nav-4';
                        _this.changeMinStatus[4] = true;
                        mao.click();
                }
            }else{
                // 回到个人资料
                _this.changeMenuStatus = 'my-msg';
            }
        },
        feedbackHandler(count){
           this.feedback_count = count;
           if(this.$route.params.status){
               document.querySelector('#maoTarget').click();
           }
        }
    },
    components: {
        "my-msg": () => import("./userCenters/MyMsg.vue"), //我的消息
        "playing-record": () => import("./userCenters/PlayingRecord.vue"), //试玩记录
        "my-collect": () => import("./userCenters/MyCollect.vue"), //我的收藏
        "archive-record": () => import("./userCenters/ArchiveRecord.vue"), //存档记录
        "help-feedback": () => import("./userCenters/HelpFeedback.vue"), //帮助反馈
        "consume-record": () => import("./userCenters/ConsumeRecord.vue"), //消费记录
    },
};
</script>

<style lang="scss" scoped>
// 外层盒子
.userCenter_box {
    width: 100%;
    padding-top: 40px;
    background-color: white;
    font-family: "PingFangSC-Regular", sans-serif;
    .userCenter_content {
        width: 1100px;
        height: 830px;
        margin: 0 auto;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid rgba(250, 216, 161, 0.5);
        background-image: url("../../assets/images/user/bg_usercenter.png");
    }
}
// 大屏左侧
.userCenter_left {
    width: 21.7%;
    height: 100%;
    float: left;
    border-right: 1px solid rgba(250, 216, 161, 0.5);
    > div {
        // 个人中心(字)导航样式
        height: 22px;
        font-size: 16px;
        margin: 33px 0 18px 12.3%;
        color: #000000;
        font-weight: bold;
    }
    .msgRed_hot{
        height: 16px;
        margin-top: 2px;
        margin-left: 10px;
        border-radius: 9999rem;
        color: #fff;
        padding: 0 5px;
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        background-color: #ff6a6a;
        text-align: center;
        line-height: 16px;
    }
    ul {
        // 左侧导航列表
        padding-left: 0;
        > li {
            width: 100%;
            height: 56px;
            line-height: 56px;
            i {
                width: 24px;
                height: 24px;
                margin-left: 24px;
                margin-right: 16px;
                background-position-y: -1px;
                display: inline-block;
                vertical-align: middle;
                background-image: url("../../assets/images/user/i_left_menu.png");
            }
            span {
                vertical-align: middle;
            }
        }
        > li:hover {
            color: #d5a561;
            cursor: pointer;
        }
        > li:hover.active {
            color: white;
        }
        .active {
            background-color: #d5a561;
            color: white;
        }
    }
}
// 右侧盒子大小
.userCenter_right {
    width: 78.25%;
    height: 830px;
    overflow-y: auto;
    > div {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
    // 小屏适配
    .userCenter_min {
        >div{
            position: relative;
        }
        .min_nav {
            padding: 0 16px;
            background-color: white;
            > div {
                height: 50px;
                line-height: 50px;
                display: flex;
                flex-wrap: wrap;
                color: #606062;
                flex-direction: row;
                border-top: 1px solid #fad8a1;
                .i_nav_list {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 50%;
                    margin-right: 12px;
                    background-size: 780px;
                    transform: translateY(-50%);
                    background-image: url("../../assets/images/user/i_left_menu.png");
                }
                :nth-child(3) {
                    width: 12px;
                    height: 12px;
                    margin-top: 20px;
                    margin-left: auto;
                    background-image: url("../../assets/images/user/i_nav_min_right.png");
                    transform: rotate(90deg);
                }
                .i_nav_right {
                    transform: rotate(0deg);
                }
            }
        }
    }
}

// 锚链接要飘高
#mao4{
    position: absolute;
    height: 1px;
    width: 100%;
    top: -80px;
}

// 左侧导航列表三种图片颜色状态
.left_menu_0 > i { background-position-x: -16px; }
.left_menu_0:hover > i { background-position-x: -56px; }
.left_menu_0.active > i { background-position-x: -96px; }
.left_menu_1 > i { background-position-x: -136px; }
.left_menu_1:hover > i { background-position-x: -176px; }
.left_menu_1.active > i { background-position-x: -216px; }
.left_menu_2 > i { background-position-x: -256px; }
.left_menu_2:hover > i { background-position-x: -296px; }
.left_menu_2.active > i { background-position-x: -336px; }
.left_menu_3 > i { background-position-x: -616px; }
.left_menu_3:hover > i { background-position-x: -656px; }
.left_menu_3.active > i { background-position-x: -696px; }
.left_menu_5 > i { background-position-x: -496px; }
.left_menu_5:hover > i { background-position-x: -536px; }
.left_menu_5.active > i { background-position-x: -576px; }
// .left_menu_5 > i { background-position-x: -736px; }
// .left_menu_5:hover > i { background-position-x: -776px; }
// .left_menu_5.active > i { background-position-x: -816px; }
.left_menu_4 > i { background-position-x: -856px; }
.left_menu_4:hover > i { background-position-x: -896px; }
.left_menu_4.active > i { background-position-x: -936px;}

// 展开导航图标展开与未展开图标颜色状态
.min_nav .i-nav-1 { background-position-x: -109px;}
.min_nav.active .i-nav-1 { background-position-x: -141px;}
.min_nav .i-nav-2 { background-position-x: -204px;}
.min_nav.active .i-nav-2 { background-position-x: -236px;}
.min_nav .i-nav-3 { background-position-x: -492px;}
.min_nav.active .i-nav-3 { background-position-x: -524px;}
.min_nav .i-nav-5 { background-position-x: -397px;}
.min_nav.active .i-nav-5 { background-position-x: -428px;}
.min_nav .i-nav-4 { background-position-x: -683px;}
.min_nav.active .i-nav-4 { background-position-x: -715px;}

@media screen and (min-width: 1200px) {
    .userCenter_box .userCenter_content {
        width: 1100px;
    }
    .userCenter_min {
        display: none;
    }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .userCenter_box .userCenter_content {
        width: 1000px;
    }
    .userCenter_min {
        display: none;
    }
}
@media screen and (max-width: 1000px) and (min-width: 736px) {
   .userCenter_box .userCenter_content {
        width: 100%;
    }
    .userCenter_box {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .userCenter_min {
        display: none;
    }

}
@media screen and (max-width: 736px) and (min-width: 700px) {
   .userCenter_box .userCenter_content {
        width: 100%;
    }
    .userCenter_box {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .userCenter_min {
        display: none;
    }
    .userCenter_left .msgRed_hot{
        margin-left: 2px;
    }

}
@media screen and (max-width: 700px) and (min-width: 500px) {
    .userCenter_box {
        padding-top: 0px;
        padding-bottom: 0px;
        > div {
            height: 100%;
        }
    }
    .userCenter_box .userCenter_content,
    .userCenter_right {
        width: 100%;
        height: 100%;
    }

    .userCenter_right,
    .userCenter_right > div {
        overflow: unset;
    }

    .userCenter_left,
    .userCenter_max {
        display: none;
    }
    .userCenter_min {
        display: block;
        overflow: unset;
    }
}
@media screen and (max-width: 500px) {
    .userCenter_box .userCenter_content,
    .userCenter_right {
        width: 100%;
        height: 100%;
    }
    .userCenter_right,
    .userCenter_right > div {
        overflow: unset;
    }
    .userCenter_box {
        padding-top: 0px;
        padding-bottom: 0px;
        > div {
            height: 100%;
        }
    }
    .userCenter_left,
    .userCenter_max {
        display: none;
    }
    .userCenter_min {
        display: block;
        overflow: unset;
    }
}
</style>
<style lang="scss">
.el-scrollbar__wrap {
    overflow: auto;
}
</style>
